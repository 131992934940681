import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import { HelmetDatoCms } from "gatsby-source-datocms"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import { media } from "@styles/breakpoints"
import Layout from "@utils/layout"

const AllFeatures = ({ data }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={data.datoCmsAllFeature.seoMetaTags}/>
      <div data-datocms-noindex>
        <Section xlTop lg light>
          <Container containerSmall>
            <Heading html="h1" level="h1" center>
              {data.datoCmsAllFeature.title}
            </Heading>
            <Paragraph level="md" center>
              {data.datoCmsAllFeature.headerContent}
            </Paragraph>
          </Container>
        </Section>
        <Section mdTop>
          <Container>
            <Flex alignStart>
              <Nav>
                <Heading html="h6" level="ssh" gray>
                  Sections
                </Heading>
                {data.datoCmsAllFeature.features.map((feature, index) => {
                  let slug = feature.title
                  slug = slug.replace(/\s+/g, "-").toLowerCase()
                  return (
                    <SectionLink
                      to={slug}
                      activeClass="-active"
                      key={index}
                      smooth={true}
                      spy={true}
                      offset={-150}
                    >
                      <Paragraph level="sm" noMargin>
                        {feature.title}
                      </Paragraph>
                    </SectionLink>
                  )
                })}
              </Nav>
              <Column>
                {data.datoCmsAllFeature.features.map((feature, index) => {
                  let slug = feature.title
                  slug = slug.replace(/\s+/g, "-").toLowerCase()
                  return (
                    <Feature key={index} name={slug}>
                      <SectionTitle>
                        <Heading html="h2" level="h3">
                          {feature.title}
                        </Heading>
                      </SectionTitle>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: feature.content,
                        }}
                      />
                    </Feature>
                  )
                })}
              </Column>
            </Flex>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default AllFeatures

export const fetchAllFeatures = graphql`
  query fetchAllFeatures {
    datoCmsAllFeature {
      title
      headerContent
      features {
        title
        content
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`

const SectionTitle = styled.div`
  padding-bottom: var(--spacing--xxs);
`

const Feature = styled.div`
  margin-bottom: var(--spacing--md);

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    flex: 0 1 100%;
    padding: 0 var(--spacingGutter);
    padding-bottom: 1.25rem;
    padding-left: 1.5rem;
    position: relative;

    ${media.xs`
      flex: 0 1 50%;
    `}

    ${media.sm`
      flex: 0 1 33%;
    `}

    &:before {
      background-color: transparent;
      border-radius: 0;
      border: solid #0ab7a8;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      content: "";
      width: 6px;
      height: 12px;
      left: 0.5rem;
      position: absolute;
      top: 0.25rem;
    }

  }

  h5 {
    color: var(--gray--60);
    margin-top: var(--spacing--xxs);
    margin-bottom: 1.5rem;
  }
`

const Nav = styled(Column)`
  display: none;
  padding-bottom: var(--spacing--md);

  ${media.sm`
    display: block;
    flex: 0 0 320px;
    position: sticky;
    top: 10rem;
  `}
`

const SectionLink = styled(ScrollLink)`
  cursor: pointer;
  display: block;
  margin-bottom: 0.75rem;
  position: relative;
  text-decoration: none;

  p {
    transition: var(--transitionBase);
  }

  &:before {
    background-color: var(--colorPrimary);
    border-radius: 1rem;
    content: "";
    height: 0.35rem;
    left: -1rem;
    opacity: 0;
    position: absolute;
    top: 0.5rem;
    transition: var(--transitionBase);
    width: 0.35rem;
  }

  &:hover {
    p {
      color: var(--colorPrimary);
    }
  }

  &.-active {
    p {
      color: var(--colorPrimary);
    }

    &:before {
      opacity: 1;
    }
  }
`
